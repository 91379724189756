
import { Preferences } from '@capacitor/preferences';

export const TOKEN_KEY = 'creditwallet-customer-Token';
export const USER_KEY = 'creditwallet-customer';
export const FCM_TOKEN = 'fcm-token';
export async function addToStorage(key: string, value: any): Promise<void> {
    await Preferences.set({
        key: key,
        value: JSON.stringify(value),
    });
}
export async function getFromStorage(key: string): Promise<any> {
    const item = await Preferences.get({ key: key });
    return JSON.parse(item.value);
}
export async function removeFromStorage(key: string): Promise<void> {
    await Preferences.remove({
        key: key,
    });
}

export async function clearStorage(): Promise<void> {
    await Preferences.clear();
}
